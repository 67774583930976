<template>
  <div v-loading.fullscreen.lock="isLoading">
    <template>
      <lottie-player
        :src="pictureShow"
        background="transparent"
        speed="1"
        style="width: 550px; height: 550px"
        loop
        autoplay
        :key="pictureRefreshKey"
      ></lottie-player>
    </template>
    <el-button
      type="primary"
      @click="changePicture"
      v-if="!showPictureRandom"
      class="left_200"
      >我要其他動畫(／‵Д′)／~</el-button
    >
    <el-button
      type="primary"
      @click="stopPicture"
      v-if="showPictureRandom"
      class="left_200"
      >停在這個動畫(*≧∀≦*)</el-button
    >
  </div>
</template>



<script>
import Vue from "vue";
export default {
  name: "Dashboard",
  components: {},
  created() {
    this.openWindow();
  },
  data() {
    return {
      isLoading: false, // 是否加載數據
      //初始化圖片
      nowTime: null,
      pictureShow:
        "https://assets4.lottiefiles.com/private_files/lf30_eT3qCd.json",
      //換圖用
      otimer: null, //計時器終止用
      showPictureRandom: false, //決定出現哪個button
      pictureRefreshKey: "",
      pictureUrls: [
        "https://assets7.lottiefiles.com/datafiles/A9zB6wzqAra9ijf/data.json",
        "https://assets7.lottiefiles.com/private_files/lf30_kh4qi0dn.json",
        "https://assets6.lottiefiles.com/packages/lf20_ox5eapmh.json",
        "https://assets7.lottiefiles.com/packages/lf20_quav0yfn.json",
        "https://assets7.lottiefiles.com/packages/lf20_z9ed2jna.json",
        "https://assets6.lottiefiles.com/packages/lf20_0vcrmosu.json",
        "https://assets10.lottiefiles.com/packages/lf20_c8szgzpw.json",
        "https://assets4.lottiefiles.com/private_files/lf30_e3tmoL.json",
        "https://assets4.lottiefiles.com/private_files/lf30_eT3qCd.json",
        "https://assets3.lottiefiles.com/packages/lf20_lkcv3xga.json",
        "https://assets5.lottiefiles.com/packages/lf20_urgxqz4c.json",
        "https://assets5.lottiefiles.com/packages/lf20_zrn901s1.json",
        "https://assets1.lottiefiles.com/packages/lf20_HwRTPu.json",
        "https://assets1.lottiefiles.com/packages/lf20_pjkfdzti.json",
        "https://assets10.lottiefiles.com/packages/lf20_yKumIH.json",
        "https://assets10.lottiefiles.com/packages/lf20_s7cmfp5g.json",
        "https://assets1.lottiefiles.com/packages/lf20_veismngi.json",
        "https://assets1.lottiefiles.com/packages/lf20_ojjzrjc8.json",
        "https://assets3.lottiefiles.com/packages/lf20_fsfbqbph.json",
        "https://assets3.lottiefiles.com/packages/lf20_2vhccjpg.json",
        "https://assets10.lottiefiles.com/packages/lf20_urr8jb9p.json",
      ],
    };
  },
  methods: {
    openWindow() {
      this.nowTime = new Date().getHours();
      this.setpictureShowUrl();
    },
    //依時間設定初始動畫
    setpictureShowUrl() {
      if (this.nowTime >= 6 && this.nowTime < 9) {
        this.pictureShow =
          "https://assets10.lottiefiles.com/packages/lf20_c8szgzpw.json";
        this.pictureRefreshKey = Math.random(); //強制渲染圖片
      } else if (this.nowTime >= 9 && this.nowTime < 12) {
        this.pictureShow =
          "https://assets7.lottiefiles.com/packages/lf20_quav0yfn.json";
        this.pictureRefreshKey = Math.random(); //強制渲染圖片
      } else if (this.nowTime >= 12 && this.nowTime < 14) {
        this.pictureShow =
          "https://assets7.lottiefiles.com/packages/lf20_z9ed2jna.json";
        this.pictureRefreshKey = Math.random(); //強制渲染圖片
      } else if (this.nowTime >= 14 && this.nowTime < 16) {
        this.pictureShow =
          "https://assets6.lottiefiles.com/packages/lf20_ox5eapmh.json";
        this.pictureRefreshKey = Math.random(); //強制渲染圖片
      } else if (this.nowTime >= 16 && this.nowTime < 18) {
        this.pictureShow =
          "https://assets7.lottiefiles.com/datafiles/A9zB6wzqAra9ijf/data.json";
        this.pictureRefreshKey = Math.random(); //強制渲染圖片
      } else if (this.nowTime >= 18 && this.nowTime < 22) {
        this.pictureShow =
          "https://assets7.lottiefiles.com/private_files/lf30_kh4qi0dn.json";
        this.pictureRefreshKey = Math.random(); //強制渲染圖片
      } else {
        this.pictureShow =
          "https://assets6.lottiefiles.com/packages/lf20_0vcrmosu.json";
        this.pictureRefreshKey = Math.random(); //強制渲染圖片
      }
    },
    //換動畫
    changePicFun() {
      //將原圖從list中刪除
      let i = this.pictureUrls.findIndex((item) => {
        return item == this.pictureShow;
      });
      if (i >= 0) {
        this.pictureUrls.splice(i, 1);
      }
      //抽取新圖
      const NewPicture =
        this.pictureUrls[Math.floor(Math.random() * this.pictureUrls.length)];
      //將原圖加回list
      this.pictureUrls.splice(this.pictureUrls.length - 1, 0, this.pictureShow);
      //更換新圖
      this.$nextTick(function () {
        this.pictureShow = NewPicture;
        this.showPictureRandom = true;
        this.pictureRefreshKey = Math.random(); //強制渲染圖片
      });
    },
    //更換成跑馬燈模式
    changePicture() {
      this.changePicFun();
      this.otimer = setInterval(() => {
        this.changePicFun();
      }, 6000);
    },
    //暫停跑馬燈
    stopPicture() {
      clearInterval(this.otimer);
      this.showPictureRandom = false;
    },
  },
};
</script>
<style scoped>
.left_200 {
  margin-left: 150px;
}
</style>
